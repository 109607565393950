import { createRouter, createWebHistory } from 'vue-router'
import TasksView from '@/views/Tasks/TasksView'
import TaskView from '@/views/Tasks/TaskView'
import ProjectsView from '@/views/Programs/ProjectsView'
import CallbackView from '@/views/CallbackView.vue'
import DefaultLayout from '@/layouts/default.vue'
import { tokenService } from '@/services/token'
import UsersAndRolesView from '@/views/UsersAndRolesView.vue'
import store from '@/store'
import ProgramsView from '@/views/Programs/ProgramsView'
import CalendarView from '@/views/Calendar/CalendarView.vue'
import { roles } from '@/constants/roles'
import ProjectView from '@/views/Project/ProjectView.vue'
import ProjectPreview from '@/views/Project/ProjectPreview.vue'
import LoginView from '@/views/LoginView.vue'

const routes = [
  {
    path: '/programs',
    name: 'programs',
    component: DefaultLayout,
    meta: {
      privatePage: true,
      canUser: true,
      canMonitor: false,
      canExpert: true,
      canManager: true,
      isSelections: true,
    },
    children: [
      {
        path: '/programs',
        name: 'programs-list',
        component: ProjectsView,
      },
      {
        path: '/programs/:id',
        name: 'programs-view-data',
        component: ProgramsView,
      },
      {
        path: '/programs/create/:code1C',
        name: 'create-project-by-code-1c',
        component: ProjectView,
      },
      {
        path: '/program/preview/:id',
        name: 'project-preview',
        component: ProjectPreview,
      },
      {
        path: '/edit',
        name: 'edit-project',
        component: ProjectView,
      },
      {
        path: '/project/:id',
        name: 'view-project',
        component: ProjectView,
      },
    ],
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: DefaultLayout,
    meta: {
      privatePage: true,
      canUser: true,
      canMonitor: true,
      canManager: true,
      isSelections: true,
    },
    children: [
      {
        path: '/calendar',
        name: 'calendar-view',
        component: CalendarView,
      },
    ],
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: DefaultLayout,
    meta: {
      privatePage: true,
      canUser: true,
      canMonitor: true,
      canManager: true,
      canExpert: true,
      isSelections: true,
    },
    children: [
      {
        path: '/tasks',
        name: 'tasks-list',
        component: TasksView,
      },
      {
        path: '/tasks/:id',
        name: 'task-by-id',
        component: TaskView,
      },
    ],
  },
  {
    path: '/',
    name: 'default',
    redirect: '/programs',
    meta: {
      privatePage: true,
      canMonitor: true,
      canUser: true,
    },
    component: DefaultLayout,
  },
  {
    path: '/users',
    name: 'users',
    component: DefaultLayout,
    meta: {
      privatePage: true,
      canAdmin: true,
      canMonitor: true,
      canManager: true,
      isSelections: true,
    },
    children: [
      {
        path: '/users',
        name: 'usersList',
        component: UsersAndRolesView,
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      privatePage: false,
    },
  },
  {
    path: '/callback',
    name: 'callback',
    component: CallbackView,
    meta: {
      privatePage: false,
    },
  },
  {
    path: '/personal-account-v2-auth',
    name: 'personal-account',
    component: CallbackView,
    props: true,
    meta: {
      privatePage: true,
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash, behavior: 'smooth' }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, behavior: 'smooth' }
    }
  },
})

router.beforeEach(async (to, from, next) => {
  if (to.name === 'formId') {
    const fullPath = to.fullPath
    localStorage.setItem('entryPath', fullPath)
  }
  const { privatePage } = to.meta
  const isLoggedIn = Boolean(tokenService.getToken())

  if (!isLoggedIn) {
    if (to.fullPath.includes(process.env.VUE_APP_REDIRECT_KEY_LK)) {
      if (to.query?.token) {
        tokenService.saveToken(to.query?.token)
        next('/')
      }
    }
  }

  if (privatePage && !isLoggedIn) {
    localStorage.setItem('redirectRoute', to.fullPath)
    next({ name: 'login' })
    return
  } else if (!privatePage && !isLoggedIn) {
    return next()
  }

  if (!privatePage && isLoggedIn) {
    next('/')
  }
  if (!store.getters['user/currentUserId'] && isLoggedIn) {
    await store.dispatch('user/getUserInfo')
  }

  const isAdmin = store.getters['user/isAdmin']
  const userRoles = store.getters['user/currentRoles']
  if (
    process.env.VUE_APP_MAINTENANCE_FLAG &&
    JSON.parse(process.env.VUE_APP_MAINTENANCE_FLAG) &&
    !isAdmin
  ) {
    window.location.href = '/maintenance.html'
    return
  }
  let accessRoute = []
  userRoles?.forEach((role) => {
    if (role in roles) {
      accessRoute = [...accessRoute, ...roles[role]]
    }
  })

  if (!userRoles.length) {
    return next('/')
  }
  if (!accessRoute.length) {
    window.location.href = '/support.html'

    return
  } else if (accessRoute.includes(to.name)) {
    next()
    return
  } else {
    next({ name: accessRoute[0] })
    return
  }
})

export default router
