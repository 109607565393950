import { axiosCustom } from '@/plugins/axios'

export class User {
  updateToken(renewtoken) {
    return axiosCustom
      .post('/v1/users/renewtoken', { renew_token: renewtoken })
      .then((response) => response.data)
  }
  getCurrentUserInfo() {
    return axiosCustom.get('/v1/users/info').then((response) => response.data)
  }

  getUserInfoById(userId) {
    return axiosCustom.get(`/v1/users/${userId}`).then((response) => response.data)
  }

  updateInfoByUser(userId, form) {
    return axiosCustom.patch(`/v1/users/${userId}/update`, form).then((response) => response.data)
  }

  setGroupToUser(form) {
    return axiosCustom.post('/v1/users/setgroups', form).then((response) => response.data)
  }

  getUsers(filters) {
    const params = new URLSearchParams({
      ...filters,
    }).toString()

    return axiosCustom.get(`/v1/users?${params}`).then((response) => response.data)
  }

  getUsersByGroup(group, page = 1) {
    const params = new URLSearchParams({
      id: group,
      pageSize: 20,
      page,
    }).toString()

    return axiosCustom.get(`/v1/user?${params}`).then((response) => response.data)
  }
}
