import { tokenService } from '@/services/token'
import { api } from '@/api/Api'

const state = {
  isLoggedIn: Boolean(tokenService.getToken()),
  accessToken: tokenService.getToken(),
  entryPath: localStorage.getItem('entryPath'),
}

const getters = {
  isLoggedIn(state) {
    return state.isLoggedIn
  },
  accessToken(state) {
    return state.accessToken
  },
  entryPath(state) {
    return state.entryPath
  },
}

const actions = {
  async login(_ctx, code) {
    try {
      const { result } = await api.auth().getAccessToken(code)
      await tokenService.saveToken(result?.token)
      await tokenService.saveAccessTokenExpires(result?.tokenExpireAt)

      return {
        success: true,
      }
    } catch (e) {
      return {
        success: false,
        errors: e.response.data.errors,
      }
    }
  },
  logout() {
    tokenService.removeToken()
    tokenService.removeAccessTokenExpires()
  },
}

const mutations = {}

export const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
