<template>
  <g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 19.04V0h3.611v16.063h8.595v2.977H0zm15.693 0V0h11.764v2.977h-8.193v4.988h6.615v2.976h-6.615v5.122h8.635v2.977H15.693zm13.95 0L36.492 0h3.74l6.848 19.04h-3.63l-1.678-4.88h-6.848l-1.65 4.88h-3.63zm6.394-7.84h5.23l-1.78-4.965c-.34-1.046-.792-2.875-.792-2.875h-.057s-.452 1.83-.792 2.875l-1.81 4.965zm14.53 7.84V0h6.754c6.112 0 10.103 3.486 10.103 9.493S63.433 19.04 57.32 19.04h-6.753zm3.488-3.36h2.883c3.915 0 6.417-2.243 6.417-6.466 0-4.17-2.556-6.414-6.417-6.414h-2.883v12.88zm17.437 3.36V0h11.763v2.977h-8.192v4.988h6.615v2.976h-6.615v5.122h8.635v2.977H71.492zm16.275 0V0h6.573c1.966 0 2.921.16 3.764.51 2.05.804 3.371 2.628 3.371 5.202 0 2.253-1.236 4.398-3.343 5.203v.053s.253.295.646.992l4.101 7.08h-4.073l-3.932-7.106H91.39v7.106h-3.624zm4.069-10.08h3.252c1.935 0 3.141-1.193 3.141-3.136 0-1.248-.477-2.247-1.57-2.691-.505-.222-1.094-.333-2.187-.333h-2.636v6.16z"
      fill="#1E1E2F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M113.923 19.04h3.487V0h-3.487v19.04zM130.069 0h-10.334v19.04h10.334l4.778-4.613V4.614L130.069 0zm-6.847 16.24h8.719V3.36h-8.719v12.88z"
      fill="#2185FB"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M131.36 17.92l3.487-3.267V4.387L131.36 1.12v16.8z"
      fill="#116CD8"
    />
  </g>
</template>

<script setup></script>
